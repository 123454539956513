* {
  box-sizing: border-box;
  font-family: aktiv-grotesk, sans-serif;
}

body {
  margin: 0;
  font-family: aktiv-grotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-purple: #46425A;
  --primary-green: #79C7BC;
}

p {
  color: #fff;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 200;
  z-index: 51;
  font-size: 1rem;
}

