.landing_page__container {
    position: relative;
    font-family: aktiv-grotesk-thin, sans-serif;
    font-weight: 200;
    font-style: normal;
    background-color: #000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section {
    height: 100vh;
    overflow: hidden;
    max-width: 100vw;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.button_no_fill {
    max-width: 230px;
    min-width: 80px;
    width: 190px;
    height: 40px;
    border-radius: 7.5px;
    outline: none;
    border: none;
    background-color: transparent;
    border: #fff solid 1px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.button_fill {
    max-width: 230px;
    min-width: 80px;
    width: 190px;
    height: 40px;
    border-radius: 7.5px;
    outline: none;
    border: none;
    background-color: var(--primary-green);
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.radial_gradient {
    position: absolute;
    z-index: 1;
    height: 120vh;
    width: 120%;
    left: -10%;
    top: -60vh;
    background: radial-gradient(circle, rgba(70, 66, 90, 1) 5%, rgba(0, 0, 0, 1) 50%);
}

h2 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 300;
    width: 100vw;
    text-align: center;
    margin-bottom: 40px;
}

h3 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 15px 0;
    font-size: 1.5rem;
}

h4 {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 5px 0;
}

@media screen and (min-width: 2500px) {

    p,
    li {
        font-size: 1.5rem !important;
        line-height: 1.8rem !important;
    }

    h2,
    h3 {
        font-size: 1.8rem !important;
    }

    h4 {
        font-size: 1.5rem !important;
    }

    button,
    .button_fill,
    .button_no_fill {
        font-size: 1.5rem !important;
    }
}

@media screen and (min-width: 1900px) {

    p,
    li {
        font-size: 1.3rem !important;
        line-height: 1.6rem !important;
    }

    h2,
    h3 {
        font-size: 1.6rem !important;
    }

    h4 {
        font-size: 1.3rem !important;
    }

    button,
    .button_fill,
    .button_no_fill {
        font-size: 1.3rem !important;
    }
}

/* SECTION POPOUT WINDOW */

.popout_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 101;
    background-color: transparent;
    backdrop-filter: blur(7.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: opacity 500ms ease-in-out;
    opacity: 0;
}

.popout_container.active {
    pointer-events: all;
    opacity: 1;
}

.popout_container .popout_box {
    position: relative;
    width: 50%;
    height: 30%;
    background-color: #232323;
    border: var(--primary-purple) solid 1px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popout_container .popout_box .popout_close {
    position: absolute;
    top: 7.5px;
    right: 10px;
    color: black;
    background-color: var(--primary-green);
    width: 25px;
    height: 25px;
    border-radius: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
}

.popout_content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.popout_content h4 {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.3rem;
}

.popout_content input {
    border: var(--primary-purple) 1px solid;
    background-color: transparent;
    height: 45px;
    width: 80%;
    border-radius: 7.5px;
    padding: 0 10px;
    color: #fff;
    font-size: 1rem;
}

.popout_content input:focus {
    outline: var(--primary-purple) 1px solid;
    background-color: rgba(70, 66, 99, 0.25);
}

.popout_content button {
    width: 80%;
    height: 45px;
    border-radius: 7.5px;
    border: none;
    background-color: var(--primary-green);
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popout_container.black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: #000;
}

.popout_container.black .go_back {
    position: absolute;
    top: 12.5vh;
    left: 10vw;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.popout_container.black .go_back:hover {
    color: var(--primary-green);
}

.popout_container.black .go_back svg {
    font-size: 1.7rem;
}

.popout_container.black .signin_options {
    width: 50%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
}

.popout_container.black .signin_options .signin_option {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: var(--primary-green) 2px solid;
}

.popout_container.black .signin_options .signin_option {
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 500ms ease-in-out;
}

.popout_container.black .signin_options .signin_option:hover {
    background-color: var(--primary-green);
    border: #000 2px solid;
}

.popout_container.black .signin_options .signin_option:hover h4 {
    color: #000;
    font-weight: 700;
}

.popout_container.black .signin_options .signin_option h4 {
    font-size: 1.5rem;
    line-height: 2rem;
}


/* SECTION HEADER */

header {
    position: fixed;
    height: 10vh;
    width: 80%;
    top: 0px;
    z-index: 100;
    border-bottom: rgba(70, 66, 99, 0.6) 1px solid;
    opacity: 1;
    transition: all 500ms ease-in-out;
}

header.hidden {
    opacity: 0;
    pointer-events: none;
}

header.background {
    background-color: #000;
}

.header_content {
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    height: 10vh;
    width: 100%;
}

.header_content a {
    height: 10vh;
}

.header_content img {
    height: 100%;
}

.header_content .menu_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    height: 100%;
    margin: 0;
}

.header_content .menu_bar a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.header_content .menu_bar li {
    list-style: none;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 200;
    font-size: 1.1rem;
}

.header_content .menu_bar__buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: right;
    gap: 7.5px;
    height: 100%;
}

.header_content .menu_bar__buttons button {
    width: 50%;
    height: 60%;
}

.header_content .menu_bar__buttons a {
    width: 50%;
    height: 60%;
}

@media screen and (min-width: 2500px) {
    .menu_bar li {
        font-size: 1.8rem !important;
        line-height: 1.8rem !important;
    }

}

@media screen and (min-width: 1900px) {
    .menu_bar li {
        font-size: 1.6rem !important;
        line-height: 1.6rem !important;
    }

}

/* !SECTION */

/* SECTION MAIN PAGE */

.landing_page__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding-top: 15vh;
    padding-bottom: 60px;
    height: 100vh;
    justify-content: space-between;
}

.landing_page__page .radial_gradient {
    position: absolute;
    height: 120vh;
    width: 120%;
    left: -10%;
    top: -60vh;
    background: radial-gradient(circle, rgba(70, 66, 90, 1) 5%, rgba(0, 0, 0, 1) 40%);
}

.landing_page__page h1 {
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 800;
    font-style: normal;
    z-index: 51;
    color: var(--primary-green);
    font-weight: 800;
    font-size: 4.5rem;
    line-height: 70px;
    text-align: center;
    margin: 0;
    word-spacing: 10px;
    width: 100%;
}

.landing_page__page p {
    text-align: center;
    line-height: 20px;
    margin: 30px;
}

.landing_page__page p:nth-of-type(2) {
    text-align: center;
    line-height: 20px;
    margin: 15px;
}

.landing_page__page .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 51;
}

.landing_page__page .brands {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;
    opacity: 0.8;
}

.landing_page__page .brands a {
    height: 80px;
}

.landing_page__page .brands #opius {
    height: 60px;
}

.landing_page__page .brands #gingerlo {
    height: 40px;
}

.landing_page__page .brands #heyu {
    height: 40px;
}

.landing_page__page .brands #pomton {
    height: 40px;
}

.landing_page__page .brands img {
    height: 100%;
}

a.button_fill,
a.button_no_fill {
    height: 6vh;
    width: 15vw;
}

@media screen and (min-width: 2500px) {
    .landing_page__page h1 {
        font-size: 7rem;
        line-height: 7rem;
    }

    .landing_page__page p {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 0;
    }
}

@media screen and (min-width: 1900px) {
    .landing_page__page h1 {
        font-size: 6rem;
        line-height: 6rem;
    }

    .landing_page__page p {
        margin: 0;
    }
}

/* !SECTION */

/* SECTION PROBLEMS */

#problems {
    align-items: center;
}

.problems {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr 1fr;
    height: 100%;
    width: 85vw;
    gap: 4vw;
}

.problem__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.problem__container svg {
    height: 25%;
    width: 25%;
}

.problem__container p {
    line-height: 1.1rem;
    text-align: center;
    margin: 0;
}

/* !SECTION */

/* SECTION SOLUTION PAGE */

.our_solution__page {
    align-items: center;
    padding-bottom: 15px;
}

.solution__container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 90vw;
    border-radius: 15px;
    border: 1px solid rgba(70, 66, 90, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solution__container h3 {
    position: relative;
    z-index: 51;
}

.solution__container .solution_topics {
    position: relative;
    z-index: 51;
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 20%;
    gap: 30px;
    margin-bottom: 10px;
}

.solution__container .solution_topics .topic {
    position: relative;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 400ms ease-in-out;
}

.solution__container .solution_topics .topic.selected {
    border: var(--primary-green) solid 1px;
    background-color: rgba(121, 199, 188, 0.15);
}

.solution__container .solution_topics .topic p {
    line-height: 1.1rem;
}

.solution__container .solution_topics .title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
}

.solution__container .solution_topics .title svg {
    height: 25px;
    width: 25px;
}

.solution__container .screenshot {
    position: relative;
    z-index: 51;
    height: calc(80% - 100px);
    border-radius: 10px;
}

.solution__container .content {
    position: relative;
    z-index: 51;
    height: calc(80% - 100px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
}

.solution__container .content img {
    height: 25%;
    border-radius: 10px;
}

#ordering-app .solution__container .content {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0;
    height: calc(100% - 85px);
}

#ordering-app .content .content_image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#ordering-app .content img {
    height: 100%;
}

#ordering-app .solution_topics {
    display: flex;
    flex-direction: column;
    align-items: flex-sart;
    justify-content: flex-start;
    padding: 30px 60px;
    width: 100%;
    height: 100%;
}

#ordering-app .solution_topics .topic li {
    color: #fff;
    font-family: aktiv-grotesk, sans-serif;
    line-height: 1.2rem;
}

#mobile .solution_topics {
    width: 50%;
}

/* !SECTION */

/*  SECTION TESTIMONIALS */

.testimonials {
    display: flex;
    align-items: top;
    justify-content: center;
    width: 80%;
    height: calc(100% - 200px);
    padding: 100px 0;
}

.testimonials .testimonial {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px;
}

.testimonials .testimonial p {
    line-height: 1.1rem;
}

.testimonials .testimonial_stars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 25px;
    width: 100%;
    grid-column: span 2;
}

.testimonials .testimonial_stars svg {
    height: 100%;
}

.testimonials .testimonial_photo_and_name {
    display: flex;
    gap: 15px;
}

.testimonials .testimonial_photo_and_name img {
    width: 33%;
    border-radius: 50%;
}

.testimonials .testimonial_photo_and_name h4 {
    line-height: 1.2rem;
    text-align: left;
}

.testimonials .testimonial_photo_and_name .name_tags p {
    font-weight: 300;
}

/*  !SECTION  */

/*  SECTION PRICING  */

.pricing_container {
    width: 80%;
    height: calc(100% - 110px);
}

.period_box_container {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.period_box_container .period_box {
    display: flex;
    padding: 5px;
    border: var(--primary-purple) solid 1px;
    border-radius: 15px;
    width: 20vw;
    max-width: 350px;
    min-width: 250px;
    height: 60px;
}

.period_box_container .period_box>p {
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.period_box_container .period_box>p.selected {
    background-color: var(--primary-purple);
}

.period_box_container .period_box>p:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.period_box_container .period_box>p:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.period_box_container>p {
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
}

.period_box_container .pricing_types {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: 50px;
}

.period_box_container .pricing_types>p {
    font-size: 1rem;
    opacity: 0.5;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

.period_box_container .pricing_types>p.selected {
    opacity: 1;
    text-decoration: underline;
}

.price_options_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    width: 100%;
    height: calc(100% - 120px);
    padding-top: 30px;
}

.price_option {
    position: relative;
    border: var(--primary-purple) 1px solid;
    height: 100%;
    width: 25vw;
    overflow: hidden;
    border-radius: 15px;
    padding: 2%;
}

.price_option:nth-child(2) {
    border-color: yellow;
}

.price_option .best_value {
    position: absolute;
    top: 0;
    right: 15px;
    background-color: yellow;
    padding: 2.5% 10px;
    z-index: 51;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price_option .best_value p {
    font-weight: 800;
    color: #000;
    margin: 0;
    font-size: 0.8rem;
}

.price_option .title {
    position: relative;
    z-index: 51;
    text-transform: uppercase;
    color: #fff;
    font-weight: 200;
    font-size: 1.2rem;
}

.price_option .price_wrapper {
    position: relative;
    z-index: 51;
    color: #fff;
    width: 100%;
    margin: 35px 0;
}

.price_option .price_wrapper .price {
    display: flex;
    gap: 2.5px;
    align-items: center;
    font-weight: 800;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    height: 30px;
}

.price_option .price_wrapper .price .period {
    font-size: 0.75rem;
    font-weight: 200;
    letter-spacing: 0.1rem;
    padding-top: 12.5px;
}

.price_option .price_wrapper .billing_info {
    font-size: 0.75rem;
    font-weight: 200;
    opacity: 0.5;
    letter-spacing: 0.1rem;
}

.price_option .features {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 51;
}

.price_option .features .feature {
    display: flex;
    align-items: center;
    gap: 10px;
}

.price_option .features .feature svg {
    max-width: 25px;
    max-height: 25px;
}

.price_option .features .feature .feature--description {
    display: flex;
    align-items: center;
    gap: 7.5px;
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #fff;
}

.price_option .features .feature .feature--description>p {
    font-size: 0.75rem !important;
    font-weight: 200;
    letter-spacing: 0.05rem;
    margin: 0;
}

.price_option button {
    position: absolute;
    bottom: 5%;
    z-index: 51;
    height: 15%;
    width: 85%;
    margin-top: 25px;
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: var(--primary-green);
    font-size: 1.15rem;
    font-weight: 700;
    cursor: pointer;
}


@media screen and (max-width: 1280px) {

    .price_options_container {
        gap: 30px;
    }

    .price_option .features {
        gap: 5px;
    }

    .price_option .features .feature .feature--description {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }
}

/*  !SECTION  */

/*  SECTION CONTACT FOOTER  */

footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 25px;
    position: relative;
    height: 25vh;
    width: 100vw;
    padding: 25px 10%;
    color: #fff;
}

footer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

footer img {
    width: 100%;
}

footer p {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 2.1rem;
}

footer a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
}

footer .legal_links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
}

/*  !SECTION  */